<template>
  <div>
    <!--<PageHeader :titulo="titulo"/>-->
    <titulo-seccion>
      <template slot="area-rutas">
        <a href="/">Inicio</a> / <span class="active">Transmisiones</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Transmisiones</h3>
      </template>
    </titulo-seccion>
    <section class="noticias py-0 my-4">
      <div class="container p-4">
        <div class="row h-100">
          <div id="box-transmision" class="col-lg-8 col-md-12 col-sm-12 p-0">
            <img
              v-if="vitem && vitem.logo"
              v-lazy="vitem.logo"
              :alt="vitem.titulo"
            />
            <!-- <div class="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                            <iframe v-if="video" class="embed-responsive-item" :src="video" allowfullscreen></iframe>
                        </div> -->

            <b-embed
              class="embed-responsive embed-responsive-16by9"
              v-if="video"
              type="iframe"
              aspect="16by9"
              :allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              :src="video"
            />
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="mt-3 pt-4" id="scrollspy-example" style="height: 60vh">
              <ul
                class="media-list"
                :style="{
                  '--background-primary': config.disenio.datos.color_secundario,
                }"
              >
                <b-media
                  tag="li"
                  v-for="(item, index) in items"
                  :key="index"
                  class="col-xs-12 cursor"
                  @click="selectVideo(item)"
                >
                  <template #aside>
                    <img
                      v-if="item.logo"
                      v-lazy="item.logo"
                      width="64"
                      alt="placeholder"
                    />
                    <!-- <b-img v-if="item.logo" :src="item.logo" width="64" alt="placeholder"></b-img> -->
                    <b-img
                      v-else
                      src="images/live.jpg"
                      width="64"
                      alt="placeholder"
                    ></b-img>
                  </template>
                  <p class="card-title">
                    <strong>{{ item.titulo }}</strong>
                  </p>
                  <small class="text-muted">{{ item.published_at }}</small>
                </b-media>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
//import PageHeader from '/src/components/PageHeader.vue';
import { BMedia, BImg } from "bootstrap-vue";
import TituloSeccion from "@/views/secciones/TituloSeccion.vue";
export default {
  components: {
    //PageHeader,
    // BEmbed,
    BMedia,
    BImg,
    TituloSeccion,
  },
  directives: {},
  data() {
    return {
      titulo: "Transmiciones",
      items: [],
      video: "",
      buscar: "",
      vitem: null,
    };
  },
  metaInfo: {
    title: "Ministerio Público",
    titleTemplate: "%s | Transmiciones",
    htmlAttrs: {
      lang: "es-ES",
    },
    meta: [{ name: "description", content: "Transmiciones" }],
  },
  created() {
    this.listarVideos();
  },
  watch: {
    buscar: function (val) {
      if (val == "" || val.length == 0) {
        this.listarVideos();
      }
    },
  },

  methods: {
    listarVideos() {
      var url = "/admin/transmisiones";
      axiosIns
        .get(url)
        .then((res) => {
          this.items = res.data;
          if (this.items.length) {
            this.video = this.items[0].url;
            this.vitem = this.items[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectVideo(data) {
      //console.log(data)
      this.vitem = data;
      this.video = data.url;
    },
    buscarVideoList() {
      this.listarVideos();
    },
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
  },
};
</script>

<style>
.cursor {
  cursor: pointer;
}

.media-bordered .media:not(:first-child) {
  border-top: 1px solid #ebe9f1;
}

.card-title {
  font-size: 14px;
  text-align: justify;
}

.box-imagen {
  height: 2opx;
}

.box-imagen img {
  width: 100%;
  height: 100%;
}

.box-imagen1 img {
  object-fit: contain;
}

@media (max-width: 700px) {
  .box-imagen {
    height: 5rem;
  }

  .embed-responsive-4by3 {
    height: calc(var(--vh, 1vh) * 100 - 10rem) !important;
  }
}
</style>
